import React from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
  CFormFeedback,
  CFormLabel,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilLockLocked, cilUser } from '@coreui/icons';
import { useFormik } from 'formik';
import { Redirect, Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import endpoints from 'src/api/endpoint';
import httpClient from 'src/api/http-client';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const navigate = useNavigate();

  const addFormInitialValues = {
    email: '',
    otp: '',
    password: '',
    confirm_password: '',
  };

  const addFormValidationSchema = Yup.object({
    email: Yup.string().required('Email is required').email(),
    password: Yup.string().required('Password is required'),
    confirm_password: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    otp: Yup.string().required('OTP is required'),
  });
  const addForm = useFormik({
    initialValues: addFormInitialValues,
    validationSchema: addFormValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      //   setShowOtp(true);
      //   navigate('/login');

      try {
        const { data } = await httpClient.post(endpoints.auth.resetPassword(), {
          password: values.password,
          email: values.email,
          otp: values.otp,
        });
        navigate('/login');
        toast.success('Password reseted successfully !! Please Login With Your New Password');
      } catch (error) {
        error.response.data.message?.split(',').forEach((element) => {
          toast.error(element);
        });
      }

      setLoading(false);
    },
  });

  // if (localStorage.getItem('token')) {
  //   navigate('/');
  // }

  return (
    <>
      <CForm onSubmit={addForm.handleSubmit}>
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md={5}>
                <CCardGroup>
                  <CCard className="p-4">
                    <CCardBody>
                      <h1>Reset Password</h1>
                      <p className="text-medium-emphasis"></p>
                      {step == 3 && (
                        <>
                          {' '}
                          <CRow>
                            <CCol md="12">
                              {' '}
                              <CRow>
                                <CFormLabel className="col-sm-4 col-form-label">
                                  Password
                                </CFormLabel>
                                <CCol sm={8}>
                                  <CFormInput
                                    type="password"
                                    invalid={
                                      !!(addForm.touched.password && addForm.errors.password)
                                    }
                                    {...addForm.getFieldProps('password')}
                                  />
                                  {!!(addForm.touched.password && addForm.errors.password) && (
                                    <CFormFeedback invalid>{addForm.errors.password}</CFormFeedback>
                                  )}
                                </CCol>
                              </CRow>
                            </CCol>
                            <CCol md="12" className="mt-4">
                              {' '}
                              <CRow>
                                <CFormLabel className="col-sm-4 col-form-label">
                                  Confirm Password
                                </CFormLabel>
                                <CCol sm={8}>
                                  <CFormInput
                                    type="password"
                                    invalid={
                                      !!(
                                        addForm.touched.confirm_password &&
                                        addForm.errors.confirm_password
                                      )
                                    }
                                    {...addForm.getFieldProps('confirm_password')}
                                  />
                                  {!!(
                                    addForm.touched.confirm_password &&
                                    addForm.errors.confirm_password
                                  ) && (
                                    <CFormFeedback invalid>
                                      {addForm.errors.confirm_password}
                                    </CFormFeedback>
                                  )}
                                </CCol>
                              </CRow>
                            </CCol>
                          </CRow>
                          <CCol xs={6}>
                            <CButton
                              color="primary"
                              className="px-4"
                              disabled={loading}
                              type="submit"
                            >
                              Submit{' '}
                              {loading && <CSpinner component="span" size="sm" className="mr-2" />}
                            </CButton>
                          </CCol>
                        </>
                      )}
                      {step == 2 && (
                        <>
                          <CInputGroup className="mb-4">
                            <CInputGroupText>
                              <CIcon icon={cilLockLocked} />
                            </CInputGroupText>
                            <CFormInput
                              type="password"
                              placeholder="Enter OTP"
                              //   autoComplete="current-password"
                              invalid={!!(addForm.touched.otp && addForm.errors.otp)}
                              {...addForm.getFieldProps('otp')}
                            />{' '}
                            {!!(addForm.touched.otp && addForm.errors.otp) && (
                              <CFormFeedback invalid>{addForm.errors.otp}</CFormFeedback>
                            )}
                          </CInputGroup>
                          <CCol xs={6}>
                            <CButton
                              color="primary"
                              className="px-4"
                              disabled={!addForm.values.otp?.length}
                              onClick={async () => {
                                // setStep(3);

                                try {
                                  const { data } = await httpClient.post(
                                    endpoints.auth.verityotp(),
                                    { email: addForm.values.email, otp: addForm.values.otp },
                                  );
                                  setStep(3);

                                  // toast.success('User Login Successfully ! ');
                                } catch (error) {
                                  error.response.data.message?.split(',').forEach((element) => {
                                    toast.error(element);
                                  });
                                }
                              }}
                            >
                              Next{' '}
                              {loading && <CSpinner component="span" size="sm" className="mr-2" />}
                            </CButton>
                          </CCol>
                        </>
                      )}
                      {step == 1 && (
                        <>
                          <CInputGroup className="mb-4">
                            <CInputGroupText>
                              <CIcon icon={cilUser} />
                            </CInputGroupText>
                            <CFormInput
                              type="text"
                              placeholder="Enter Email Id"
                              //   autoComplete="current-password"
                              invalid={!!(addForm.touched.email && addForm.errors.email)}
                              {...addForm.getFieldProps('email')}
                            />{' '}
                            {!!(addForm.touched.email && addForm.errors.email) && (
                              <CFormFeedback invalid>{addForm.errors.email}</CFormFeedback>
                            )}
                          </CInputGroup>
                          <CCol xs={6}>
                            <CButton
                              color="primary"
                              className="px-4"
                              disabled={!addForm.values.email?.length}
                              onClick={async () => {
                                // setStep(2);

                                try {
                                  const { data } = await httpClient.post(
                                    endpoints.auth.verifyEmail(),
                                    { email: addForm.values.email },
                                  );
                                  setStep(2);

                                  toast.success('Enter The OTP Send To Your Mail ! ');
                                } catch (error) {
                                  error.response.data.message?.split(',').forEach((element) => {
                                    toast.error(element);
                                  });
                                }
                              }}
                            >
                              Next{' '}
                              {loading && <CSpinner component="span" size="sm" className="mr-2" />}
                            </CButton>
                          </CCol>
                        </>
                      )}
                      <CRow>
                        <CCol xs={6} className="text-right">
                          {/* <CButton color="link" className="px-0">
                          Forgot password?
                        </CButton> */}
                        </CCol>
                      </CRow>
                    </CCardBody>
                  </CCard>
                </CCardGroup>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      </CForm>

      <ToastContainer position="top-right" autoClose={5000} />
    </>
  );
};

export default ResetPassword;
