import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CRow,
  CFormSelect,
  CFormFeedback,
  CSpinner,
} from '@coreui/react';
import { useFormik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import * as Yup from 'yup';
import endpoints from 'src/api/endpoint';
import rydrendpoints from 'src/api/rydr_endpoint';
import httpClient from 'src/api/http-client';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const EditBusDetails = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [detailLoading, setDetailLoading] = useState(true);
  const [driverList, setDriverList] = useState([]);
  const { slug } = useParams();

  const loadDetail = async () => {
    try {
      const { data } = await httpClient.get(rydrendpoints.busMaster.editBusDetails(slug));
      console.log('Data', data);
      const driverMobileNumber = driverList.find(
        (driver) => driver.number == data.driverMobileNumber,
      );

      editForm.setFieldValue('busNumber', data.busNumber || '');
      editForm.setFieldValue('busModel', data.busModel || '');
      editForm.setFieldValue('busCompany', data.busCompany || '');
      editForm.setFieldValue('busColor', data.busColor || '');
      editForm.setFieldValue('busProvider', data.busProvider || '');
      editForm.setFieldValue('totalSeats', data.totalSeats || '');
      editForm.setFieldValue('passengerSeats', data.passengerSeats || '');
      editForm.setFieldValue('driverId', data.driverId || '');
      editForm.setFieldValue('driverMobileNumber', driverMobileNumber ? driverMobileNumber : '');
      editForm.setFieldValue('driverName', data.driverName || '');
      // editForm.setFieldValue('numberOfSeatPerRows', data.numberOfSeatPerRows || '');
      // editForm.setFieldValue('numberOfColumns', data.numberOfColumns || '');
      // editForm.setFieldValue('numberOfSeatPerCols', data.numberOfSeatPerCols || '');
      // editForm.setFieldValue('lastRowSeats', data.lastRowSeats || '');
      // editForm.setFieldValue('insuranceCompany', data.insuranceCompany || '');
      // editForm.setFieldValue('policyNumber', data.policyNumber || '');
      // editForm.setFieldValue('manufacturingMonth', data.manufacturingMonth || '');
      // editForm.setFieldValue('insuranceValidUpto', data.insuranceValidUpto || '');
      // editForm.setFieldValue('registrationDate', data.registrationDate || '');
      // editForm.setFieldValue('fitnessValidUpto', data.fitnessValidUpto || '');
      // editForm.setFieldValue('taxValidUpto', data.taxValidUpto || '');
      // editForm.setFieldValue('pollutionValidUpto', data.pollutionValidUpto || '');
    } catch (error) {}
    setDetailLoading(false);
  };

  const loadDriver = async () => {
    try {
      const { data } = await httpClient.get(rydrendpoints.shuttleDriver.getList());
      console.log(data);
      setDriverList(
        data.results.map((v) => ({ label: v.FirstName, value: v.id, number: v.mobileNumber })),
      );
      console.log('setDriverList', driverList);
    } catch (error) {}
  };

  useEffect(() => {
    // if (JSON.parse(localStorage.getItem('permission'))?.LastMileConnectivity.update) {
    loadDriver();
    // } else {
    //   navigate('/lmc-geoLocation');
    // }
  }, []);

  useEffect(() => {
    if (!!driverList.length) {
      loadDetail();
    }
  }, [driverList]);

  const seatLayout = [
    [
      { point: '0,0', isSeatSelected: false, seatNo: 1, type: 'Available' },
      { point: '0,1', isSeatSelected: false, seatNo: 2, type: 'Available' },
      { point: '0,2', type: 'EmptySpace' },
      { point: '0,3', type: 'Driver' },
    ],
    [
      { point: '1,0', type: 'EmptySpace' },
      { point: '1,1', type: 'EmptySpace' },
      { point: '1,2', isSeatSelected: false, seatNo: 3, type: 'Available' },
      { point: '1,3', isSeatSelected: false, seatNo: 4, type: 'Available' },
    ],
    [
      { point: '2,0', isSeatSelected: false, seatNo: 5, type: 'Available' },
      { point: '2,1', type: 'EmptySpace' },
      { point: '2,2', isSeatSelected: false, seatNo: 6, type: 'Available' },
      { point: '2,3', isSeatSelected: false, seatNo: 7, type: 'Available' },
    ],
    [
      { point: '3,0', isSeatSelected: false, seatNo: 8, type: 'Available' },
      { point: '3,1', type: 'EmptySpace' },
      { point: '3,2', isSeatSelected: false, seatNo: 9, type: 'Available' },
      { point: '3,3', isSeatSelected: false, seatNo: 10, type: 'Available' },
    ],
    [
      { point: '4,0', isSeatSelected: false, seatNo: 11, type: 'Available' },
      { point: '4,1', type: 'EmptySpace' },
      { point: '4,2', isSeatSelected: false, seatNo: 12, type: 'Available' },
      { point: '4,3', isSeatSelected: false, seatNo: 13, type: 'Available' },
    ],
    [
      { point: '5,0', isSeatSelected: false, seatNo: 14, type: 'Available' },
      { point: '5,1', isSeatSelected: false, seatNo: 15, type: 'Available' },
      { point: '5,2', isSeatSelected: false, seatNo: 16, type: 'Available' },
      { point: '5,3', isSeatSelected: false, seatNo: 17, type: 'Available' },
    ],
  ];

  const editFormInitialValues = {
    busNumber: '',
    busCompany: '',
    busModel: '',
    busColor: '',
    busProvider: '',
    totalSeats: '',
    passengerSeats: '',
    driverId: '',
    driverMobileNumber: '',
    driverName: '',
    seatLayout: [],
    // fuelType: '',
    // engineNumber: '',
    // chasisNumber: '',
    // insuranceCompany: '',
    // policyNumber: '',
    // permitDetails: '',
    // manufacturingMonth: '',
    // numberOfRows: '',
    // numberOfSeatPerRows: '',
    // numberOfColumns: '',
    // numberOfSeatPerCols: '',
    // lastRowSeats: '',
    // registrationDate: '',
    // fitnessValidUpto: '',
    // insuranceValidUpto: '',
    // pollutionValidUpto: '',
  };

  // const editFormValidationSchema = Yup.object({
  //     geolocationServiceOf: Yup.string().required('GeoLocation is required'),
  // });
  const editForm = useFormik({
    initialValues: editFormInitialValues,
    // validationSchema: editFormValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const req = {
        busNumber: editForm.values.busNumber,
        busCompany: editForm.values.busCompany,
        busModel: editForm.values.busModel,
        busColor: editForm.values.busColor,
        busProvider: editForm.values.busProvider,
        totalSeats: editForm.values.totalSeats,
        passengerSeats: editForm.values.passengerSeats,
        driverId: editForm.values.driverMobileNumber.value,
        driverMobileNumber: editForm.values.driverMobileNumber.number,
        driverName: editForm.values.driverMobileNumber.label,
        seatLayout: seatLayout,
      };
      console.log('req', req);
      try {
        await httpClient.patch(rydrendpoints.busMaster.updateBusDetails(slug), req);
        toast.success('Bus Master Updated Successfully!');
        navigate('/bus-master');
      } catch (error) {
        error.response.data.message?.split(',').forEach((element) => {
          toast.error(element);
        });
      }
      setLoading(false);
    },
  });

  return (
    <>
      <CForm onSubmit={editForm.handleSubmit}>
        <div className="main_header">
          <CRow>
            <CCol md="6">
              <h6>Edit Bus Details</h6>
            </CCol>
            <CCol md="6" className="text-end">
              <Link to="/bus-master">
                <CButton className="me-3" style={{ minWidth: 120 }} variant="outline" type="button">
                  Cancel
                </CButton>
              </Link>
              <CButton
                style={{ minWidth: 120 }}
                type="submit"
                disabled={loading}
                // setVisible(false);
              >
                Save {loading && <CSpinner component="span" size="sm" className="mr-2" />}
              </CButton>
            </CCol>
          </CRow>
        </div>

        <CCard>
          <CCardBody>
            <CRow className="mb-3">
              <CCol md="6">
                <CRow>
                  <CFormLabel className="col-sm-4 col-form-label">Bus Number</CFormLabel>
                  <CCol sm={8}>
                    <CFormInput
                      type="text"
                      invalid={!!(editForm.touched.busNumber && editForm.errors.busNumber)}
                      {...editForm.getFieldProps('busNumber')}
                    />{' '}
                    {!!(editForm.touched.busNumber && editForm.errors.busNumber) && (
                      <CFormFeedback invalid>{editForm.errors.busNumber}</CFormFeedback>
                    )}
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <CFormLabel className="col-sm-4 col-form-label">Bus Model</CFormLabel>
                  <CCol sm={8}>
                    <CFormInput
                      type="text"
                      invalid={!!(editForm.touched.busModel && editForm.errors.busModel)}
                      {...editForm.getFieldProps('busModel')}
                    />{' '}
                    {!!(editForm.touched.busModel && editForm.errors.busModel) && (
                      <CFormFeedback invalid>{editForm.errors.busModel}</CFormFeedback>
                    )}
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow className="mb-3">
              <CCol md="6">
                <CRow>
                  <CFormLabel className="col-sm-4 col-form-label">Bus Color</CFormLabel>
                  <CCol sm={8}>
                    <CFormInput
                      type="text"
                      invalid={!!(editForm.touched.busColor && editForm.errors.busColor)}
                      {...editForm.getFieldProps('busColor')}
                    />{' '}
                    {!!(editForm.touched.busColor && editForm.errors.busColor) && (
                      <CFormFeedback invalid>{editForm.errors.busColor}</CFormFeedback>
                    )}
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <CFormLabel className="col-sm-4 col-form-label">Bus Provider</CFormLabel>
                  <CCol sm={8}>
                    <CFormInput
                      type="text"
                      invalid={!!(editForm.touched.busProvider && editForm.errors.busProvider)}
                      {...editForm.getFieldProps('busProvider')}
                    />{' '}
                    {!!(editForm.touched.busProvider && editForm.errors.busProvider) && (
                      <CFormFeedback invalid>{editForm.errors.busProvider}</CFormFeedback>
                    )}
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow className="mb-4">
              <CCol md="6">
                <CRow>
                  <CFormLabel className="col-sm-4 col-form-label">Total Seats</CFormLabel>
                  <CCol sm={8}>
                    <CFormInput
                      type="text"
                      invalid={!!(editForm.touched.totalSeats && editForm.errors.totalSeats)}
                      {...editForm.getFieldProps('totalSeats')}
                    />{' '}
                    {!!(editForm.touched.totalSeats && editForm.errors.totalSeats) && (
                      <CFormFeedback invalid>{editForm.errors.totalSeats}</CFormFeedback>
                    )}
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <CFormLabel className="col-sm-4 col-form-label">Passenger Seats</CFormLabel>
                  <CCol sm={8}>
                    <CFormInput
                      type="text"
                      invalid={
                        !!(editForm.touched.passengerSeats && editForm.errors.passengerSeats)
                      }
                      {...editForm.getFieldProps('passengerSeats')}
                    />{' '}
                    {!!(editForm.touched.passengerSeats && editForm.errors.passengerSeats) && (
                      <CFormFeedback invalid>{editForm.errors.passengerSeats}</CFormFeedback>
                    )}
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow className="mb-4">
              <CCol md="6">
                <CRow>
                  <CFormLabel className="col-sm-4 col-form-label">Driver</CFormLabel>
                  <CCol sm={8}>
                    <Select
                      options={driverList}
                      placeholder=""
                      // isMulti
                      value={editForm.values.driverMobileNumber}
                      onChange={(selectedOption) =>
                        editForm.setFieldValue(
                          'driverMobileNumber',
                          selectedOption ? selectedOption : '',
                        )
                      }
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CForm>
    </>
  );
};

export default EditBusDetails;
