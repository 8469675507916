import axios from 'axios';

const httpClient = axios.create();

httpClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers = {
    key: `${process.env.REACT_APP_TOKEN_KEY}`,
    Authorization: token ? `Bearer ${token}` : '',
  };
  // config.headers = { key: 'efb11082f54703d1' };
  return config;
});

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    if (Number(error.response.status) === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

export default httpClient;
