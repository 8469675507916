import React from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
  CFormFeedback,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilLockLocked, cilUser } from '@coreui/icons';
import { useFormik } from 'formik';
import { Redirect, Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import endpoints from 'src/api/endpoint';
import httpClient from 'src/api/http-client';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const VendorLogin = () => {
  const [loading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);

  const navigate = useNavigate();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const addFormInitialValues = {
    mobileNumber: '',
  };

  const addFormValidationSchema = Yup.object({
    mobileNumber: Yup.string()
      .required('Mobile No is required')
      .matches(phoneRegExp, 'Phone number is not valid'),
    // otp: Yup.string().required('OTP is required'),
  });
  const addForm = useFormik({
    initialValues: addFormInitialValues,
    validationSchema: addFormValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      //   setShowOtp(true);
      try {
        const { data } = await httpClient.post(endpoints.auth.sendOTP(), {
          mobileNumber: addForm.values.mobileNumber,
        });
        setShowOtp(true);
      } catch (error) {
        error.response.data.message?.split(',').forEach((element) => {
          toast.error(element);
        });
      }

      setLoading(false);
    },
  });
  const otpFormInitialValues = {
    otp: '',
  };
  const otpFormValidationSchema = Yup.object({
    otp: Yup.string().required('OTP is required'),
  });
  const otpForm = useFormik({
    initialValues: otpFormInitialValues,
    validationSchema: otpFormValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      //   setShowOtp(true);
      try {
        const { data } = await httpClient.post(endpoints.auth.vendor_login(), {
          mobileNumber: addForm.values.mobileNumber,
          otp: otpForm.values.otp,
        });
        // toast.success('User Login Successfully ! ');
        let menu = {};
        data.data.user?.permissions?.map((v) => {
          menu[v.name.split(' ').join('')] = v.permissions;
        });

        localStorage.setItem('token', data.data?.tokens?.access?.token || '');
        localStorage.setItem('userDetails', JSON.stringify(data.data?.user) || '');
        localStorage.setItem('permission', JSON.stringify(menu) || '');
        localStorage.setItem('loginType', 'vendor' || '');

        navigate('/');
      } catch (error) {
        error.response.data.message?.split(',').forEach((element) => {
          toast.error(element);
        });
      }

      setLoading(false);
    },
  });
  // if (localStorage.getItem('token')) {
  //   navigate('/');
  // }

  return (
    <>
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={5}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <h1>Vendor Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>

                    {!showOtp ? (
                      <>
                        <CForm onSubmit={addForm.handleSubmit}>
                          <CInputGroup className="mb-3">
                            <CInputGroupText>
                              <CIcon icon={cilUser} />
                            </CInputGroupText>
                            <CFormInput
                              placeholder="Mobile No"
                              autoComplete=""
                              invalid={
                                !!(addForm.touched.mobileNumber && addForm.errors.mobileNumber)
                              }
                              {...addForm.getFieldProps('mobileNumber')}
                            />
                            {!!(addForm.touched.mobileNumber && addForm.errors.mobileNumber) && (
                              <CFormFeedback invalid>{addForm.errors.mobileNumber}</CFormFeedback>
                            )}
                          </CInputGroup>
                          <CCol xs={6}>
                            <CButton
                              color="primary"
                              className="px-4"
                              disabled={loading}
                              type="submit"
                            >
                              Send OTP
                              {loading && <CSpinner component="span" size="sm" className="mr-2" />}
                            </CButton>
                          </CCol>
                        </CForm>
                      </>
                    ) : (
                      <>
                        <CForm onSubmit={otpForm.handleSubmit}>
                          <CInputGroup className="mb-4">
                            <CInputGroupText>
                              <CIcon icon={cilLockLocked} />
                            </CInputGroupText>
                            <CFormInput
                              type="password"
                              placeholder="OTP"
                              //   autoComplete="current-password"
                              invalid={!!(otpForm.touched.otp && otpForm.errors.otp)}
                              {...otpForm.getFieldProps('otp')}
                            />{' '}
                            {!!(otpForm.touched.otp && otpForm.errors.otp) && (
                              <CFormFeedback invalid>{otpForm.errors.otp}</CFormFeedback>
                            )}
                          </CInputGroup>
                          <CCol xs={6}>
                            <CButton
                              color="primary"
                              className="px-4"
                              disabled={loading}
                              type="submit"
                            >
                              Login{' '}
                              {loading && <CSpinner component="span" size="sm" className="mr-2" />}
                            </CButton>
                          </CCol>
                        </CForm>
                      </>
                    )}
                    <CRow>
                      <CCol xs={6} className="text-right">
                        {/* <CButton color="link" className="px-0">
                          Forgot password?
                        </CButton> */}
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
      <ToastContainer position="top-right" autoClose={5000} />
    </>
  );
};

export default VendorLogin;
